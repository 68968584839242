import { axisBottom, axisLeft, format } from "d3";
import { one } from "../utils";

/**
 * @param {d3.Selection} svg
 */
export function axes(
  svg,
  {
    width,
    height,
    title = null,
    titleOffset = 25,
    xScale,
    xAxisLabelText,
    xAxisLabelOffset,
    yScale,
    yAxisLabelText,
    yAxisLabelOffset,
    marginLeft,
    marginBottom,
  },
) {
  // Axes
  xAxis(svg, { height, marginBottom, xScale });
  yAxis(svg, { marginLeft, yScale });
  // Axis Labels
  xAxisLabel(svg, {
    xAxisLabelText,
    width,
    height,
    marginBottom,
    xAxisLabelOffset,
  });
  yAxisLabel(svg, {
    yAxisLabelText,
    yAxisLabelOffset,
    height,
  });
  // Title
  const [min, max] = xScale.range();
  const innerWidth = max - min;
  svg
    .selectAll("text.plot-title")
    .data(title ? [null] : [])
    .join("text")
    .attr("class", "plot-title")
    .attr("x", marginLeft + innerWidth / 2)
    .attr("y", titleOffset)
    .attr("text-anchor", "middle")
    .text(title);
}

function xAxis(svg, { height, marginBottom, xScale, tickDensity = 50 }) {
  const [min, max] = xScale.range();
  const innerWidth = max - min;
  const ticks = innerWidth / tickDensity;
  one(svg, "g", "x-axis")
    .attr("transform", `translate(0,${height - marginBottom})`)
    .transition()
    // @ts-ignore
    .call(axisBottom(xScale).ticks(ticks));
}

function yAxis(svg, { marginLeft, yScale, tickDensity = 50 }) {
  const [max, min] = yScale.range();
  const innerHeight = max - min;
  const ticks = innerHeight / tickDensity;
  one(svg, "g", "y-axis")
    .attr("transform", `translate(${marginLeft},0)`)
    .transition()
    // @ts-ignore
    .call(axisLeft(yScale).ticks(ticks).tickFormat(format(".3s")));
}

function xAxisLabel(
  svg,
  { xAxisLabelText, width, height, marginBottom, xAxisLabelOffset },
) {
  one(svg, "text", "x-axis-label")
    .attr("x", width / 2)
    .attr("y", height - marginBottom + xAxisLabelOffset)
    .attr("text-anchor", "middle")
    .text(xAxisLabelText);
}

function yAxisLabel(svg, { yAxisLabelText, yAxisLabelOffset, height }) {
  one(svg, "text", "y-axis-label")
    .attr("text-anchor", "middle")
    .attr(
      "transform",
      `translate(${yAxisLabelOffset}, ${height / 2}) rotate(-90)`,
    )
    .text(yAxisLabelText);
}
