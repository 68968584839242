import { format } from "d3";

export const formatNumber = format(",");
export const formatPerc = format(".0%");

/**
 * @param {string?} s
 */
export const getDate = (s) => {
  if (s === undefined) return undefined;
  const date = new Date(s);
  const offset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + offset);
};

export function observeResize({ state, setState, container, suffix = "" }) {
  const widthName = `width${suffix}`;
  const heightName = `height${suffix}`;
  if (state[widthName] === undefined) {
    const resizeObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      setState((state) => ({
        ...state,
        [widthName]: width,
        [heightName]: height,
      }));
    });
    resizeObserver.observe(container);
    return null;
  }
  return { [widthName]: state[widthName], [heightName]: state[heightName] };
}

/**
 * Akin to React's useMemo, for use in D3-based rendering.
 * Memoizes only one computed value.
 * Accepts a function fn and dependencies, like useMemo.
 * Also accepts a DOM node and a name for the memoization.
 * The memoized value and previous dependencies are stored
 * on the DOM node, using the given name.
 */
export function memoize(fn, dependencies, domNode, name = "default") {
  const property = `__memoized-${name}`;
  const memoized = domNode[property];
  if (memoized && dependencies.length === memoized.dependencies.length) {
    let dependenciesChanged = false;
    for (let i = 0; i < dependencies.length; i++) {
      if (dependencies[i] !== memoized.dependencies[i]) {
        dependenciesChanged = true;
        break;
      }
    }
    if (!dependenciesChanged) {
      return memoized.value;
    }
  }

  // If we got here, either it's the first run,
  // or dependencies have changed.
  const value = fn();
  domNode[property] = { dependencies, value };
  return value;
}

/**
 * Selects by appending a single element
 *
 * @param {d3.Selection} selection
 * @param {string} name
 * @param {string} className
 */
export const one = (selection, name, className) =>
  selection
    .selectAll(`${name}.${className}`)
    .data([null])
    .join(name)
    .attr("class", className);
