import { flatGroup, line, max, min, scaleLinear } from "d3";
import { axes } from "./axes";

/**
 * @param {d3.Selection} container
 */
export function multiLineChart(
  container,
  {
    data,
    xValue,
    xAxisLabelText,
    xAxisLabelOffset,
    yValue,
    yAxisLabelText,
    yAxisLabelOffset,
    lineValue,
    selectedLineValue,
    colorValue,
    hoveredColorValue,
    colorScale,
    width,
    height,
    title = null,
    marginLeft,
    marginTop,
    marginRight,
    marginBottom,
  },
) {
  // Scales
  const filteredData = data.filter((d) =>
    hoveredColorValue ? hoveredColorValue === colorValue(d) : true,
  );
  const xScale = scaleLinear(
    // @ts-ignore
    [0, max(filteredData, xValue)],
    [marginLeft, width - marginRight],
  );
  const yScale = scaleLinear(
    // @ts-ignore
    [Math.min(0, min(filteredData, yValue)), max(filteredData, yValue)],
    [height - marginBottom, marginTop],
  );

  // Axes
  axes(container, {
    width,
    height,
    title,
    xScale,
    xAxisLabelText,
    xAxisLabelOffset,
    yScale,
    yAxisLabelText,
    yAxisLabelOffset,
    marginLeft,
    marginBottom,
  });

  // Multi lines
  const lineGenerator = line()
    .x((d) => xScale(xValue(d)))
    .y((d) => yScale(yValue(d)))
    .defined((d) => yValue(d) !== undefined);
  const dataGrouped = flatGroup(filteredData, lineValue, colorValue);
  // console.log("dataGrouped");
  // console.log(dataGrouped);
  container
    .selectAll("path.mark")
    .data(dataGrouped)
    .join("path")
    .attr("class", "mark")
    .attr("fill", "none")
    .attr("stroke", (d) => colorScale(d[1]))
    .attr("stroke-linecap", "round")
    .attr("stroke-width", (d) =>
      selectedLineValue ? (selectedLineValue === d[0] ? 3 : 1) : 2.5,
    )
    .attr("opacity", (d) =>
      selectedLineValue ? (selectedLineValue === d[0] ? 1 : 0.2) : 0.25,
    )
    .attr("d", (d) => lineGenerator(d[2]));
}
