import { format, index, max, scaleBand, scaleLinear, stack, union } from "d3";
import { axes } from "./axes";
import { one } from "../utils";

const formatBarLabel = format(".3s");

/**
 * @param {d3.Selection} container
 */
export function stackedBarChart(
  container,
  {
    data,
    xValue,
    xAxisLabelText,
    xAxisLabelOffset,
    yValue,
    yTotalValue,
    yAxisLabelText,
    yAxisLabelOffset,
    colorValue,
    hoveredColorValue,
    colorScale,
    selectedBar,
    setSelectedBar,
    persistenceSelection,
    setPersistenceSelection,
    width,
    height,
    marginLeft,
    marginRight,
    marginBottom,
    marginTop,
    padding,
    hoveredStrokeWidth = 2,
    hoveredStrokeColor = "black",
    fadeOpacity,
  },
) {
  const filteredData = hoveredColorValue
    ? data.filter((d) => colorValue(d) === hoveredColorValue)
    : data;
  const stackedData = stack()
    .keys(union(filteredData.map(colorValue)))
    // @ts-ignore
    .value(([, group], key) => yValue(group.get(key)))(
    // @ts-ignore
    index(filteredData, xValue, colorValue),
  );
  // console.log("stackedData");
  // console.log(stackedData);

  const xScale = scaleBand(filteredData.map(xValue), [
    marginLeft,
    width - marginRight,
  ]).padding(padding);
  const yScale = scaleLinear(
    // @ts-ignore
    [0, max(filteredData, yTotalValue)],
    [height - marginBottom, marginTop],
  );

  // Background event
  container.on("click", () => {
    if (persistenceSelection) {
      setSelectedBar(null);
      setPersistenceSelection(false);
    }
  });

  // Axes
  axes(container, {
    width,
    height,
    xScale,
    xAxisLabelText,
    xAxisLabelOffset,
    yScale,
    yAxisLabelText,
    yAxisLabelOffset,
    marginLeft,
    marginBottom,
  });

  // Stacked bars
  container
    .selectAll("g.stacks")
    .data(stackedData)
    .join("g")
    .attr("class", "stacks")
    .attr("fill", ({ key }) => colorScale(key))
    .attr("stroke-width", hoveredStrokeWidth)
    .attr("stroke", ({ key }) =>
      hoveredColorValue && hoveredColorValue === key
        ? hoveredStrokeColor
        : "none",
    )
    .attr("opacity", ({ key }) =>
      hoveredColorValue ? (hoveredColorValue === key ? 1 : fadeOpacity) : 1,
    )
    .call((selection) => {
      selection
        .selectAll("rect")
        .data((d) => d)
        .join("rect")
        .on("click", (event, d) => {
          if (d.data[0] === selectedBar) {
            setPersistenceSelection(!persistenceSelection);
          } else {
            setSelectedBar(d.data[0]);
            setPersistenceSelection(true);
          }
          event.stopPropagation();
        })
        .on("mouseenter", (event, d) => {
          if (!persistenceSelection) {
            setSelectedBar(d.data[0]);
          }
        })
        .on("mouseleave", (event, d) => {
          if (!persistenceSelection) {
            setSelectedBar(null);
          }
        })
        .attr("x", (d) => xScale(d.data[0]))
        .attr("y", (d) => yScale(d[1]))
        // @ts-ignore
        .attr("width", xScale.bandwidth())
        .attr("height", (d) => yScale(d[0]) - yScale(d[1]))
        .transition()
        .attr("opacity", (d) =>
          selectedBar ? (selectedBar === d.data[0] ? 1 : fadeOpacity) : 1,
        );
    })
    .call((selection) => {
      selection.filter(({ key }) => key === hoveredColorValue).raise();
    });

  // Labels
  let topStack = stackedData[0].map((d) => ({ x: d.data[0] }));
  for (let i = 0; i < stackedData.length; i++) {
    const d = stackedData[i];
    for (let j = 0; j < d.length; j++) {
      const x = d[j][1] - d[j][0];
      topStack[j][d.key] = x;
      if (i === stackedData.length - 1) {
        topStack[j]["total"] = d[j][1];
      }
    }
  }
  // console.log(topStack);
  one(container, "g", "bar-labels")
    .selectAll("text.bar-label")
    .data(topStack)
    .join("text")
    .attr("class", "bar-label")
    .attr("x", (d) => xScale(d.x) + xScale.bandwidth() / 2)
    .attr("text-anchor", "middle")
    // @ts-ignore
    .attr("y", (d) => yScale(d.total) - 6)
    .text((d) =>
      // @ts-ignore
      formatBarLabel(hoveredColorValue ? d[hoveredColorValue] : d.total),
    )
    .transition()
    .attr("opacity", (d) =>
      selectedBar ? (selectedBar === d.x ? 1 : fadeOpacity) : 1,
    );
}
