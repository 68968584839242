import {
  format,
  json,
  scaleOrdinal,
  schemeCategory10,
  select,
  utcFormat,
} from "d3";
import { colorLegend } from "./components/colorLegend";
import { multiLineChart } from "./components/multiLineChart";
import { stackedBarChart } from "./components/stackedBarChart";
import { formatNumber, getDate, observeResize, one } from "./utils";

const formatTime = utcFormat("%b %d");
const formatFloat = format(".2s");

const config = {
  xAxisLabelText: "Cohort",
  xAxisLabelOffset: 40,
  yAxisLabelText: "Money",
  yAxisLabelOffset: 20,
  height: 400,
  marginLeft: 70,
  marginTop: 35,
  marginRight: 10,
  marginBottom: 50,
  padding: 0.1,
  fadeOpacity: 0.2,
};

const cohortViewConfig = {
  xAxisLabelText: "Week",
  xAxisLabelOffset: 40,
  yAxisLabelText: "Money",
  yAxisLabelOffset: 20,
  height: 400,
  marginLeft: 70,
  marginTop: 35,
  marginRight: 10,
  marginBottom: 50,
  padding: 0.1,
  fadeOpacity: 0.2,
};

/**
 * @typedef {import("../bindings").BQCohortRow} BQCohortRow
 * @typedef {import("../bindings").BQCohortDetailsRow} BQCohortDetailsRow
 */

export async function plotCohorts({ containerId, loadingId, colorLegendId }) {
  const render = () =>
    viz({
      container,
      colorLegendElement,
      state,
      setState,
    });
  const container = document.getElementById(containerId);
  const loadingElement = document.getElementById(loadingId);
  const colorLegendElement = document.getElementById(colorLegendId);
  let state = {
    data: null,
    rawData: null,
    detailedData: null,
    hoveredColorValue: null,
    selectedBar: null,
    barsPersistenceSelection: false,
    colorPersistenceSelection: false,
    detailsHoveredColorValue: null,
    detailsPersistenceSelection: false,
  };
  const setState = (next, doRender = true) => {
    state = next(state);
    if (doRender) render();
  };
  getData().then(({ data, rawData }) => {
    setState((state) => ({ ...state, data, rawData }));
    select(loadingElement).transition().style("opacity", 0).remove();
  });
  getDetailsData().then((detailedData) =>
    setState((state) => ({ ...state, detailedData })),
  );
  render();
}

async function getData() {
  /** @type {Array<BQCohortRow>} */
  const source = await json("/cohorts/data");
  const rawData = source.map((d) => {
    const cohort = getDate(d.cohort);
    return {
      ...d,
      cohort,
      cohort_fmt: formatTime(cohort),
      pending_capital: Math.max(
        0,
        d.capital - d.default_capital - d.paid_capital,
      ),
      total: d.capital + d.revenue,
    };
  });
  const data = rawData.flatMap((d) => {
    return [
      "paid_capital",
      "pending_capital",
      "default_capital",
      "revenue",
    ].map((variable) => ({
      cohort: d.cohort,
      cohort_fmt: d.cohort_fmt,
      total: d.total,
      variable,
      value: d[variable],
    }));
  });
  return { data, rawData };
}

async function getDetailsData() {
  /** @type {Array<BQCohortDetailsRow>} */
  const source = await json("/cohorts/detailed_data");
  const rawData = source.map((d) => {
    const cohort = getDate(d.cohort);
    return {
      ...d,
      cohort,
      cohort_fmt: formatTime(cohort),
      pending_capital: Math.max(
        0,
        d.capital - d.default_capital - d.paid_capital,
      ),
      total: d.capital + d.revenue,
    };
  });
  return rawData.flatMap((d) => {
    return [
      "capital",
      "paid_capital",
      "pending_capital",
      "default_capital",
      "revenue",
      "margin",
    ].map((variable) => ({
      cohort: d.cohort,
      cohort_fmt: d.cohort_fmt,
      week: d.week,
      variable,
      value: d[variable],
    }));
  });
}

function viz({ container, colorLegendElement, state, setState }) {
  const dimensions = observeResize({ state, setState, container });
  const {
    data,
    rawData,
    hoveredColorValue,
    selectedBar,
    barsPersistenceSelection,
    colorPersistenceSelection,
  } = state;
  if (dimensions === null || data === null || rawData === null) return;

  const {
    xAxisLabelText,
    xAxisLabelOffset,
    yAxisLabelText,
    yAxisLabelOffset,
    height,
    marginLeft,
    marginRight,
    marginBottom,
    marginTop,
    padding,
    fadeOpacity,
  } = config;
  const { width } = dimensions;
  // console.log("data");
  // console.log(data);
  // console.log("rawData");
  // console.log(rawData);
  // console.log(hoveredColorValue);

  const colorValue = (d) => d.variable;
  const colorScale = scaleOrdinal()
    .domain(data.map(colorValue))
    .range(schemeCategory10);

  const svg = select(container)
    .selectAll("svg.main-plot")
    .data([null])
    .join("svg")
    .attr("class", "main-plot plot-background")
    .attr("width", width)
    .attr("height", height);

  // Color legend
  const setHoveredColorValue = (hoveredColorValue) =>
    setState((state) => ({ ...state, hoveredColorValue }));
  const setColorPersistenceSelection = (colorPersistenceSelection) =>
    setState((state) => ({ ...state, colorPersistenceSelection }));
  colorLegend(select(colorLegendElement), {
    colorScale,
    hoveredColorValue,
    setHoveredColorValue,
    persistenceSelection: colorPersistenceSelection,
    setPersistenceSelection: setColorPersistenceSelection,
    fadeOpacity,
  });

  // Bar chart
  const setSelectedBar = (selectedBar) =>
    setState((state) => ({ ...state, selectedBar }));
  const setBarsPersistenceSelection = (barsPersistenceSelection) =>
    setState((state) => ({ ...state, barsPersistenceSelection }));
  stackedBarChart(svg, {
    data,
    xValue: (d) => d.cohort_fmt,
    xAxisLabelText,
    xAxisLabelOffset,
    yValue: (d) => d.value,
    yTotalValue: (d) => d.total,
    yAxisLabelText,
    yAxisLabelOffset,
    colorValue,
    hoveredColorValue,
    colorScale,
    selectedBar,
    setSelectedBar,
    persistenceSelection: barsPersistenceSelection,
    setPersistenceSelection: setBarsPersistenceSelection,
    width,
    height,
    marginLeft,
    marginRight,
    marginBottom,
    marginTop,
    padding,
    fadeOpacity,
  });

  // Details view
  const details = one(select(container), "div", "details-view");

  // Hover details view
  one(details, "div", "hover-details").call((selection) =>
    hoverDetails(selection, { data: rawData, selectedBar }),
  );

  // Cohort view
  one(details, "div", "cohort-view").call((selection) =>
    cohortView(selection, { state, setState }),
  );
}

/**
 * @param {d3.Selection} container
 */
function hoverDetails(container, { data, selectedBar }) {
  // Title
  one(container, "h2", "hover-details-title")
    .text(`Cohort: ${selectedBar}`)
    .style("display", selectedBar ? "block" : "none");

  // Details
  let detailsData;
  if (selectedBar) {
    const bar = data.filter((d) => d.cohort_fmt === selectedBar)[0];
    detailsData = Object.keys(bar)
      .filter((d) => ["cohort", "cohort_fmt", "total"].indexOf(d) === -1)
      .map(
        (d) =>
          `<span>${d}:</span> ${d.startsWith("n_") ? formatNumber(bar[d]) : formatFloat(bar[d])}`,
      );
  } else {
    detailsData = ["Select a bar to see more details here."];
  }
  one(container, "div", "hover-details-content")
    .selectAll("p.details-text")
    .data(detailsData)
    .join("p")
    .attr("class", "details-text")
    .html((d) => d);
}

/**
 * @param {d3.Selection} container
 */
function cohortView(container, { state, setState }) {
  const dimensions = observeResize({
    state,
    setState,
    container: container.node(),
    suffix: "Cohort",
  });
  const {
    detailedData,
    selectedBar,
    detailsHoveredColorValue,
    detailsPersistenceSelection,
  } = state;
  if (dimensions === null) return;

  const {
    xAxisLabelText,
    xAxisLabelOffset,
    yAxisLabelText,
    yAxisLabelOffset,
    height,
    marginLeft,
    marginTop,
    marginRight,
    marginBottom,
  } = cohortViewConfig;
  const { widthCohort: width } = dimensions;

  // Loading indicator
  container
    .selectAll("p.loading-indicator")
    .data(detailedData === null ? [null] : [])
    .join("p")
    .attr("class", "loading-indicator");

  if (detailedData === null) {
    return;
  }

  // Scales
  const colorValue = (d) => d.variable;
  const colorScale = scaleOrdinal()
    .domain(detailedData.map(colorValue))
    .range(schemeCategory10);

  // Color legend
  const setDetailsHoveredColorValue = (detailsHoveredColorValue) =>
    setState((state) => ({ ...state, detailsHoveredColorValue }));
  const setDetailsPersistenceSelection = (detailsPersistenceSelection) =>
    setState((state) => ({ ...state, detailsPersistenceSelection }));
  one(container, "div", "legend-container").call((selection) =>
    colorLegend(selection, {
      colorScale,
      hoveredColorValue: detailsHoveredColorValue,
      setHoveredColorValue: setDetailsHoveredColorValue,
      persistenceSelection: detailsPersistenceSelection,
      setPersistenceSelection: setDetailsPersistenceSelection,
    }),
  );

  // Container
  const svg = container
    .selectAll("svg.cohort-plot")
    .data([null])
    .join("svg")
    .attr("class", "cohort-plot plot-background")
    .attr("width", width)
    .attr("height", height);

  // Line chart
  multiLineChart(svg, {
    data: detailedData,
    xValue: (d) => d.week,
    xAxisLabelText,
    xAxisLabelOffset,
    yValue: (d) => d.value,
    yAxisLabelText,
    yAxisLabelOffset,
    lineValue: (d) => d.cohort_fmt,
    selectedLineValue: selectedBar,
    colorValue,
    hoveredColorValue: detailsHoveredColorValue,
    colorScale,
    width,
    height,
    title: selectedBar ? `Cohort: ${selectedBar}` : "All cohorts",
    marginLeft,
    marginTop,
    marginRight,
    marginBottom,
  });
}
