/**
 * @param {d3.Selection} selection
 */
export function colorLegend(
  selection,
  {
    colorScale,
    hoveredColorValue = null,
    setHoveredColorValue = (d) => {},
    persistenceSelection = false,
    setPersistenceSelection = (d) => {},
    fadeOpacity = 0.2,
  },
) {
  selection
    .selectAll("div.legend")
    .data(colorScale.domain())
    .join("div")
    .attr("class", "legend")
    .call((selection) => {
      selection
        .selectAll("div.legend-mark")
        .data((d) => [d])
        .join("div")
        .attr("class", "legend-mark")
        .style("background-color", (d) => colorScale(d));
      selection
        .selectAll("p.legend-label")
        .data((d) => [d])
        .join("p")
        .attr("class", "legend-label")
        .text((d) => d);
    })
    .on("click", (event, d) => {
      if (d === hoveredColorValue) {
        setPersistenceSelection(!persistenceSelection);
      } else {
        setHoveredColorValue(d);
        setPersistenceSelection(true);
      }
      event.stopPropagation();
    })
    .on("mouseenter", (event, d) => {
      if (!persistenceSelection) {
        setHoveredColorValue(d);
      }
    })
    .on("mouseleave", (event, d) => {
      if (!persistenceSelection) {
        setHoveredColorValue(null);
      }
    })
    .style("opacity", (d) =>
      hoveredColorValue ? (hoveredColorValue === d ? 1 : fadeOpacity) : 1,
    );
}
